/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
@use '@angular/material' as mat;

@import 'variable';

@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
@import 'spinner';
@import 'app';
@import 'header';
@import 'widgets';
@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';
@import 'fonts';
@import 'icons/material-icons/material-icons.scss';
@import 'loading.scss';
@import 'custom-angular-material-theme.scss';


