// custom css for angular material and small screens
@media (max-width: 768px) {


  // stepper
  .mat-vertical-stepper-header {
    padding-left: 0px !important;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .mat-vertical-content {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .mat-vertical-content-container {
    margin-left: 0px !important;
  }

  .mat-stepper-vertical-line::before {
    border-left-color: rgba(0, 0, 0, 0);
  }

  // mat-Card
  .mat-card {
    .mat-card-content {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.mat-checkbox-layout {
  white-space: normal !important;
}
