/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "breakpoints";

html .page-wrapper {
  min-height: calc(100vh - 48px);
  padding: 0;
}

.mat-toolbar-single-row {
  height: 48px !important;
}

.linewrap {
  white-space: pre-wrap;
}

.mat-tab-nav-bar {
  margin: 0 0.25rem 0.25rem 0.25rem !important;
}

.ecclesia-header {
  background-color: $ecclesia-blue;
  color: $white;
}

.filter-wrapper {
  padding: 0 1rem;
  background-color: $lightbackgroundgrey;
  border-radius: 0.25rem;
  margin: 0.125rem 0 0.5rem 0;

  .outer-filter-wrapper & {
    // if used with schlagwort-header
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.filter-mb {
    margin-bottom: 1rem;
  }

  .mat-form-field-wrapper {
    padding-bottom: 1rem;
  }
}

.filter-toggle {
  white-space: nowrap;
  padding-left: 1rem;

  @include screen("md") {
    width: 100%;
    padding-left: 0;
  }
}

.link {
  cursor: pointer;
  display: inline-block;
  color: $linkcolor !important;

  &:hover {
    text-decoration: underline;
  }

  .mat-icon {
    text-decoration: none !important;
  }

  .before {
    margin-right: 0.5rem;
  }

  .after {
    margin-left: 0.5rem;
  }
}

.link-danger {
  color: $warncolor;
}

.autoscrollbar {
  .mat-drawer-inner-container {
    overflow-y: hidden;
    overflow-x: hidden;

    &:hover {
      overflow-y: auto;
    }
  }
}

#snav {
  overflow-y: hidden;

  .sidenavWrapper {
    width: 260px;
    height: 100vh;
    overflow-y: auto;
  }
}

#snav .mat-mdc-nav-list {
  margin: 0 0.5rem;
}

#snav .mat-mdc-nav-list .mat-mdc-list-item a {
  color: $linkcolorpale !important;
  font-weight: normal;

  .mat-icon {
    color: $linkcolorpale;
    margin: 0 0.5rem;
  }

  &:hover {
    transition-duration: 0.3s;
    color: $linkcolor !important;
    text-decoration: none;
  }

  .navText,
  .subNavText {
    font-size: 15px;
    font-weight: 200;
    letter-spacing: normal;
  }
}

#snav .mat-mdc-nav-list .mat-mdc-list-item.selected {
  background-color: $ecclesia-blue;

  .mat-icon {
    color: $white;
  }

  a {
    color: $white !important;

    &:hover {
      transition-duration: 0.3s;
      color: $white !important;
      text-decoration: none;
    }
  }
}

.mat-icon {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
}

.mat-step-icon {
  .mat-icon {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
  }
}

button {
  .mdc-button__label {
    letter-spacing: normal;

    .mat-icon {
      margin-bottom: -7px !important;
    }
  }
}

// submenu
#snav {
  .childlist {
    padding: 0;
    // margin-top: -10px;
    background-color: rgba(30, 64, 114, 0.125);

    .mdc-list-item__content {
      a {
        display: flex;

        .mat-icon {
          transition-duration: 0.3s;
          transform: scale(0.7) !important;
        }
      }
    }

    .selectedchild {
      .mdc-list-item__content {
        a {
          display: flex;
          color: $ecclesia-blue !important;

          .mat-icon {
            transition-duration: 0.3s;
            transform: scale(0.7) translateX(5px) !important;
            color: $ecclesia-blue !important;
          }
        }
      }
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch--unselected {
    --mdc-switch-unselected-focus-state-layer-color: #3949ab;
    --mdc-switch-unselected-handle-color: #3949ab;
    --mdc-switch-unselected-hover-state-layer-color: #3949ab;
    --mdc-switch-unselected-pressed-state-layer-color: #3949ab;
    --mdc-switch-unselected-focus-handle-color: #1a237e;
    --mdc-switch-unselected-hover-handle-color: #1a237e;
    --mdc-switch-unselected-pressed-handle-color: #1a237e;
    --mdc-switch-unselected-focus-track-color: #7986cb;
    --mdc-switch-unselected-hover-track-color: #7986cb;
    --mdc-switch-unselected-pressed-track-color: #7986cb;
    --mdc-switch-unselected-track-color: #7986cb;
  }
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.nowrap {
  white-space: nowrap;
}

.mat-table-responsive-container {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow-x: auto;
  // display: flex; FLEX causes problems with hovering highlight in responsive view
  // flex-direction: column;
  padding: 0 !important;

  table {
    width: 100%;
  }
}

.mat-mdc-table {
  .mat-mdc-header-cell {
    >div {
      padding: 1rem 0 !important;
    }
  }

  // overflow: auto;
  .mat-mdc-row {
    transition: background-color 0.3s linear;

    &:hover {
      background-color: $tablerowhovercolor !important;
    }

    td {
      padding: 0.5rem 0.25rem 0 0.25rem;
    }

    td.mat-mdc-cell:first-of-type {
      padding-left: 0.25rem;
    }
  }

  &.clickableRow {
    .mat-mdc-row {
      cursor: pointer !important;
    }
  }

  th {
    padding: 0 0.25rem !important;
  }

  // for better readability especially of numbers EC-4891
  tbody,
  tfoot {
    tr {
      td {
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }

  .rightAlign {
    width: 100%;
    text-align: right;
    padding-right: 1rem;
  }

  .centerAlign {
    width: 100%;
    text-align: center;
  }

  .rightAlignHead {
    >div {
      justify-content: flex-end;
      padding-right: 1rem;
      text-align: right;
    }
  }

  .centerAlignHead {
    >div {
      justify-content: center;
      text-align: center;
    }
  }

  .activeRow {
    background-color: $tablerowactivecolor;
  }

  .mat-mdc-footer-row {
    background-color: $list-footer-row;
  }
}

.mat-header-cell {
  &.cdk-drag {
    cursor: col-resize;

    & .mat-sort-header-container {
      cursor: col-resize;
    }
  }
}

.border-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  border-radius: 5px;
}

.toggle-container {
  margin: 1rem 0;
}

.mat-vertical-content-container {
  // give stepper a bit more top space
  padding: 0.5rem 0 0 0;

  .mat-vertical-stepper-content {
    padding-top: 0.25rem;
  }
}

mat-icon.clear {
  position: relative;
  float: right;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 18px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
}

.mat-button-focus-overlay {
  // removes focus highlight, that otherwise stays highlighted after dialog closes
  background-color: transparent !important;
}

.cdk-overlay-pane {
  // overwrite max-width of 80vw for modal dialogs.
  max-width: none !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #dadada;
  margin-bottom: 0.25rem !important;
}

.mdc-tab__text-label {
  letter-spacing: normal;
}

.tab-inner-wrapper {
  padding-top: 1rem;
}

.mat-mdc-tab-body-wrapper {
  height: 100% !important;
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.hideTab div.mat-tab-label:nth-child(1) {
  display: none;
}

.input-suffix {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

// Editor tinyMCE
.tox {
  &.tox-tinymce {
    border: none;
    height: 100% !important;
    min-height: 280px;
    // min-height: calc(100% - 1rem) !important;
    // border:1px solid red !important;
  }
}

.mdc-text-field__input {
  width: calc(100% - 1.5rem) !important;
}

.mat-mdc-text-field-wrapper {
  .fill & {
    padding: 0 !important;
  }
}

.mdc-text-field {
  padding: 0 !important;

  .mat-form-field-appearance-outline & {
    padding: 0 1rem !important;
  }
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  // padding-right: 0 !important;

  .fill & {
    padding-left: 0 !important;
  }
}

.mat-mdc-form-field-infix {
  .mat-mdc-floating-label {
    padding: 0 !important;
    margin: 0 !important;
    top: 40px !important;
  }
}

.mat-mdc-form-field {
  .mat-mdc-floating-label.mdc-floating-label {
    // problems with floating-label in mat-form-field containing a lib-datepicker-field (EC-7483)
    pointer-events: none !important;
  }
}

.editorWrapper {
  max-height: calc(100% - 3.125rem) !important;
  height: calc(100% - 16.5rem) !important;
  min-height: 315px;

  .editorFormfieldWrapper {
    height: 100% !important;

    .mat-mdc-text-field-wrapper {
      height: 100% !important;
      min-height: 100%;
      padding: 0;

      .mat-mdc-form-field-flex {
        height: 100% !important;
        min-height: 100%;
      }

      .mat-mdc-form-field-infix {
        height: 100% !important;
        min-height: 100%;

        >editor {
          height: 100%;
          min-height: 100%;
        }
      }
    }
  }
}

.error_tiny {
  margin-top: 1rem;
}

.mat-mdc-input-element {
  letter-spacing: normal !important;
}

.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block !important;
}

.timeinputwrapper {
  input[type="time"] {
    width: 100% !important;

    &::-webkit-calendar-picker-indicator {
      position: relative;
      line-height: 20px;
      width: 18px;
      height: 18px;
      opacity: 0.6;
      scale: 0.75;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAB4klEQVRYw+2Xu04CQRSGP5FC5VKojUJlCI8ANiY8gK0xvgEQKys0EDuMvRJM7Gyx0UTCW0iobdRgIsTCBiwMFsyOK8zuzu6GTUw4W56z55/5z3VgLv9BQmxzwi0d3hgxokubBiUyLPh3nuScV0YW3zNVNr07X6POl6Vz4xtywaoX9wf0HZ0bX489d87DXE2cskWBHCmiREmRo0CL4R+bS8K67ld4MP3YpUBUaRelQNdkec+y3ul/3Q+oELG1jlBhYILQuEXddPas1o2zpnvUnENrmLZJaMcsQVv+t2+fmH15+qTLeunKjLJJ2prkXk3OWGtF1EDmk+UpjLIqW1jYAUBZJrUFuWeSnogngIikqapuaS9Cnbd0YQ8AedmjQioOjQvGPQPEZXUrYngsVE0bB04A0BQ2pWlVQ6iKvgCKwqYxreoIVc4XQE7YdKZVPaFK+wJIy3KbEiM8MV8AMZkqPgAy3gD0KfrmmnX3FOkEeZcnYfXBIYvugqyTprDEqWxqj+y4SVOdQhvLFney+99MrC02habTKlRkfXKk1yp0mp2arLpes9Np1yqy3uUEc2jXOgNHJRv6A8d5ZNrvFo4jM4ChP/O1JYDFK4DVcebLbwDrewAPkECeUAE8AgN5xs5l9vIDWYMHeVY1mfoAAAAASUVORK5CYII=");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      cursor: pointer;
    }
  }
}

.mdc-text-field--filled {
  background-color: transparent !important;
}

.mat-mdc-card {
  display: flex;
  padding: 0 0.5rem !important;


  // height:100%;
  .mat-mdc-card-header {
    align-items: center;
    padding: 0.5rem 1.5rem 0 0.75rem !important;

    @include screen("md") {
      padding: 0.25rem 0.75rem 0 0.75rem !important;
    }

    .mat-card-header-text {
      @include screen("md") {
        margin: 0;
      }
    }
  }

  .mat-mdc-card-content {
    padding: 0.25rem 1rem 0.25rem 1rem !important;
    margin: 0 !important;
  }

  margin: 0.25rem !important;
}

.clickableIndicator {
  cursor: pointer;
}

.highlight {
  >div {
    background-image: none !important;
    animation: fadeIt 4s ease-in-out;
  }
}

.highlightfound {
  background-color: $highlightcolordark;
  font-weight: 400;
}

.activeEntry {
  background-color: $tablerowactivecolor;
}

@keyframes fadeIt {
  0% {
    background-color: transparent;
  }

  15% {
    background-color: $highlightcolor;
  }

  100% {
    background-color: transparent;
  }
}

.licenseplate {
  @include licenseplate;
  line-height: 32px;
  padding: 0 10px 0 25px;
  font-size: 1.25rem;
}

.licenseplate_small {
  @include licenseplate;
  line-height: 25px;
  padding: 0 7px 0 20px;
  font-size: 16px;
}

.mat-form-field {
  // fixes cut off placeholders in frefox
  line-height: 1.5 !important;
}

.mat-option {

  // EC-5872
  &:hover {
    background-color: $option-hover !important;
  }
}

.clearbuttondropdown {
  position: absolute;
  right: 18px !important;
  z-index: 2;
  border: none;
  background-color: transparent;
  margin: 3px 8px 0 0 !important;
  padding: 0 !important;
  width: 14px !important;
  height: 18px !important;
  font-size: 18px !important;
  line-height: 16px !important;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.mat-select-arrow {
  margin: 0 4px 0 25px !important;
}

// Action Buttons
.accent {
  color: $accentcolor !important;
}

.warn {
  color: $warncolor !important;
}

.disabled {
  color: $disabledcolor !important;
  cursor: not-allowed !important;
}

.mr {
  margin-right: 0.5rem;
}

.mat-snack-bar-container {
  background: #ffffff !important;
}

.cdk-drag-handle {
  cursor: move;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
  padding: 0.5rem .5rem 0 0;
}

// TILE VIEW
.page-content {
  padding: 0 0.25rem 0.25rem 0.25rem !important;

  .mat-drawer-container {
    background-color: transparent;
  }

  .mat-drawer-content {
    overflow-y: auto;
    min-height: 72vh;
    max-height: calc(100vh - 170px); // makes detailview scrollable
  }

  .mat-sidenav-container {
    border: 1px solid $sidenavbackground;
    border-radius: 0.25rem;

    &.no-top-border {
      // if blue header shows up we dont want upper radius
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .mat-sidenav {
    width: 400px;
    font-size: 14px;

    @include screen("lg") {
      width: 100%;
    }
  }

  /* DRAGGABLE SIDEBAR */
  .rightHandle {
    position: static;
    height: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.125);
    cursor: ew-resize;

    &:hover {
      background-color: rgba(49, 35, 119, 0.25);
    }
  }

  .scrollbarhover {
    height: 100%;
    min-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    &:hover {
      overflow-y: auto;
    }
  }

  .text_force_break {
    overflow-wrap: break-word;
    word-wrap: anywhere;

    a {
      word-break: break-all;
    }

    // max-width: calc(88vw - 600px);
    // .minitoggle & {
    //   max-width: calc(88vw - 370px);
    // }
  }

  .tile-view-header {
    display: flex;
    flex-flow: row;
    align-items: center;

    span {
      margin: 0 0 0 15px;
    }
  }

  .tile-content {
    background-color: $lightbackgroundgrey;
  }

  .outer-filter-wrapper {
    .mat-sidenav-container {
      margin-left: 1rem;

      @include screen("lg") {
        margin: 0;
      }
    }
  }
}

.custompaneloutlinemultiple {
  position: absolute;
  min-width: calc(100% + 1.5rem) !important;
  margin-top: 40px;
  margin-left: 28px !important;
}

.custompaneloutline {
  position: absolute;
  min-width: calc(100% + 1.5rem) !important;
  margin-top: 40px;
  margin-left: 0.25rem !important;
}

.custompanel {
  position: absolute;
  margin-top: 32px;
  margin-left: 40px;
  min-width: 100% !important;

  @include screen("sm") {
    margin-left: 28px;
  }
}

.custompanelsingle {
  position: absolute;
  margin-top: 32px;
  margin-left: 15px;
  min-width: 100% !important;

  @include screen("sm") {
    margin-left: 28px;
  }
}

.formStepHeadline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .fieldtitle {
    margin-right: 0.5rem !important;
  }

  .mat-icon {
    margin-bottom: 1rem;
  }
}

.maintitle {
  padding: 0;
  color: #333;
  font-size: 1.5rem !important;
  margin: 0.25rem 0 0.25rem 0.125rem !important;
  font-weight: 500 !important;
  letter-spacing: normal;
  // min-height: 2.25rem; // can't use vh unit here because it will change card height dynamically.
}

.fieldtitle {
  padding: 0;
  margin: 0.25rem 0 1rem 0.125rem !important;
  letter-spacing: normal;
}

.subtitle {
  padding: 0;
  font-size: 1rem !important; //Reduced in response to EC-7716
  margin: 0 0 0.5rem 0.5rem !important;
  letter-spacing: normal;
}

.mdc-form-field {
  label {
    letter-spacing: normal;
  }
}

// selectAll trigger checkbox
.selectall {
  .mat-checkbox-layout {
    display: inline-flex !important;
    width: 100%;

    .mat-checkbox-label {
      flex: 1 1 100%;
    }

    .mat-ripple {
      display: none;
    }
  }
}

.mat-header-cell {
  color: $defaulttextcolor;
}

.found {
  // background-color: $highlightcolor;
  font-weight: 600;
}

.error_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  div {
    .text-with-icon {
      margin-bottom: 8px;

      span {
        position: relative;
        top: -6px;
      }
    }
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $ecclesia-blue;
}

.mat-checkbox-disabled .mat-checkbox-background {
  // background-color: rgb(30, 63, 114, .5)!important;
  opacity: 0.5;
}

.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: white;
}

/**
  * The collection of fields used to display the details of a single domain object. It is the styling of a collection
  * of key value pairs where the key is a label and value is the content associated with the label.
 */
.details-field-list {
  display: flex;
  flex-flow: row;
  padding: 0.5rem 0.25rem;
  border-top: 1px solid $lightbordergrey;

  /** Structural pseudo-class that allows you to target the first occurrence of an element within its container.*/
  &:first-of-type {
    border-top: none;
  }

  .field-label {
    flex: 1 1 40%;
    text-align: left;
    // font-weight: bold;
  }

  .field-content {
    flex: 1 1 60%;
    text-align: left;
    white-space: pre-line;
  }
}

/** The common style for all list detail components */
.details-container {
  border: 1px solid rgba(0, 0, 0, 0.075);
  padding: 0.25rem 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.75);
  margin: 0 0 0.5rem 0;

  >div {
    padding: 0.5rem 0;
  }
}

/** The line used to mark the border between single detail fields */
.detail-separator {
  &:not(:last-of-type) {
    border-bottom: 1px solid #efefef;
  }
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.form-box {
  box-shadow: 0 0 0 1px rgb(158, 158, 158);
  border-radius: 4px;
  box-sizing: border-box !important;

  &:not(.disabled):hover {
    transition: 0.2s;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.87);
  }

  margin-top: 0.275em;
  margin-bottom: 1em;
  margin-left: 2px;
  margin-right: 2px;

  padding: 0 !important;

  .form-box-title {
    border: none;
    color: rgba(0, 0, 0, 0.87);
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding: 0.5rem 0;
    margin: 0;
  }
}

.custom-formcontrol-container {
  // border:1px solid rgba(0,0,0,.12);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  margin: 1px 1px 2px 1px;
  padding: 0 0.75em 0 0.75em;

  &:not(.disabled):hover {
    transition: 0.2s;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.87);
  }
}

.tox-tinymce-disabled {
  iframe body .mce-content-readonly {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

.mat-tooltip {
  color: #fff !important;
  max-width: 360px !important;
  font-size: 14px;
  font-weight: 400;
}

.mat-mdc-form-field {
  width: 100%;
  letter-spacing: normal;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-icon-suffix {
  .fill & {
    padding: 10px 0 0 0 !important;
    white-space: nowrap;
  }
}

.mat-mdc-menu-content {
  margin: 0 !important;
  overflow-y: hidden;
}

.mat-mdc-menu-panel {
  padding: 0 !important;
  margin: 0 !important;
  min-width: 150px;
}

.mat-icon {
  padding: 0 !important;
}

.dialog-padding {
  padding: 0 1.5rem;

  .mdc-dialog__title {
    padding: 0 0 0 0.5rem;
  }

  .mdc-dialog__content {
    padding: 0.25rem 0.5rem !important;
  }
}


.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #fff !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: #000000 !important;
}



// CSS Hack for label-text of form-fields overlapping outline
// only in online schadenmeldung (dynamically-set appearance bug leads to
// insufficient width of mat-form-field-outline-gap, e.g. EC-5278)
// .online-schadenmeldung {
//   .mat-form-field-label {
//     mat-label {
//       background-color: #fff;
//       padding-right: 0.5rem !important;
//     }

//     .mat-form-field-required-marker {
//       background-color: #fff;
//       padding-right: 0.5rem;
//     }
//   }

//   .dark {
//     .mat-form-field-label {
//       mat-label {
//         background-color: #424242 !important;
//         padding-right: 0.5rem !important;
//       }

//       .mat-form-field-required-marker {
//         background-color: #424242 !important;
//         padding-right: 0.5rem;
//       }
//     }
//   }
// }