$breakpoints: (
    'xs': 599px,
    'sm': 600px,
    'md': 960px,
    'lg': 1280px,
    'xl': 1920px,
    'xxl': 2560px

) !default;


@mixin screen($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint){
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    @else {
        @warn "`#{$breakpoint}` is not a valid option."
        + "Available BPs are: #{map-keys($breakpoints)}";
    }
}