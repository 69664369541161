/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// font family, also paste this import into styles.css file.
@use '@angular/material' as mat;

$md-ecclesia: (
    50 : #e4e8ee,
    100 : #bcc5d5,
    200 : #8f9fb9,
    300 : #62799c,
    400 : #405c87,
    500 : #1e3f72,
    600 : #1a396a,
    700 : #16315f,
    800 : #122955,
    900 : #0a1b42,
    A100 : #7a9cff,
    A200 : #4776ff,
    A400 : #1450ff,
    A700 : #0040f9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$custom-warn: (
    100: #C90000,
    700: #C90000,
    900: #C90000,
    contrast: (
        100 : #FFFFFF,
        700 : #FFFFFF,
        900 : #FFFFFF
    )
);

// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$bodyfont:'Poppins', sans-serif;
$headingfont:'Poppins', sans-serif;
$defaultfont:'Poppins', sans-serif;

@include mat.core();

/*Material Theme Colors*/

$primary: mat.m2-define-palette($md-ecclesia);
$accent: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$warn: mat.m2-define-palette($custom-warn, 100);
$theme: mat.m2-define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    ),
    typography: mat.m2-define-typography-config(
        $font-family: $defaultfont,
    ),
    density: 0
));
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme

@include mat.all-component-themes($theme);

// Legacy Themes from Angular versions prior to 15, can be removed in the future, just leave it in here
// if strange css behaviour occurs during testing ...
// @include mat.all-legacy-component-themes($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #8f999e;
$sidebar-icons: #99abb4 ;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #1e3f72;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;



$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
