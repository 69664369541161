@use "sass:math";

@keyframes spin {
  from {
    transform: rotate(-60deg);
  }

  to {
    transform: rotate(300deg);
  }
}

.loading-spinner,
#noscript {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

$loading-size: 125px;

/**
 * Use: <span class="loading-spin"><i></i><i></i><i></i><i></i><i></i></span>
 */
.loading-spin {
  display: block;
  flex: 0 0 $loading-size;
  width: $loading-size;
  height: $loading-size;
  margin: 20px;
  background: url('logo-spin/logo-bg.svg') center no-repeat;
  position: relative;
  background-size: percentage(math.div($loading-size, 128px));

  >i {
    position: absolute;
    left: 0;
    top: 0;
    width: $loading-size;
    height: $loading-size;
    background-size: percentage(math.div($loading-size, 128px));
    background-repeat: no-repeat;
    background-position: center;

    &:nth-child(1) {
      animation: spin 2.5s infinite linear;
      background-image: url('logo-spin/logo-1.svg');
    }

    &:nth-child(2) {
      animation: spin 1.5s infinite linear reverse;
      background-image: url('logo-spin/logo-2.svg');
    }

    &:nth-child(3) {
      animation: spin 1s infinite linear;
      background-image: url('logo-spin/logo-3.svg');
    }

    &:nth-child(4) {
      animation: spin 2s infinite linear;
      background-image: url('logo-spin/logo-4.svg');
    }

    &:nth-child(5) {
      animation: spin 1.8s infinite linear reverse;
      background-image: url('logo-spin/logo-5.svg');
    }
  }
}
